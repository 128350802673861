import { useEffect, useMemo, useState } from 'react'
import { Tabs, Tab, Box, Typography, InputAdornment, Grid } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { CommonTextField } from '../CommonTextField'
import { useIntl } from 'react-intl'
import { ApplicationCard } from '../ApplicationCard'

const filterApps = (
  apps: any[],
  filter: string,
  searchQuery: string,
  upComingAppNames: string[]
) => {
  let filtered = apps.slice() // Make a copy of the original array

  // Apply the "Connected" filter
  if (filter === 'Connected') {
    filtered = filtered.filter((app) => app.isEnabled)
  }

  // Apply the "Other" filter
  else if (filter === 'Other') {
    filtered = filtered.filter(
      (app) =>
        app.category.toLowerCase() !== 'pos' &&
        app.category.toLowerCase() !== 'channel' &&
        app.description.toLowerCase() !== 'coming soon'
    )
  }

  // Apply the "Upcoming" filter
  else if (filter === 'Upcoming') {
    filtered = filtered.filter(
      (app) => app.description.toLowerCase() === 'coming soon'
    )
  }

  // Apply category-specific filter only if it's not "All"
  if (
    filter !== 'All' &&
    filter !== 'Connected' &&
    filter !== 'Other' &&
    filter !== 'Upcoming'
  ) {
    filtered = filtered.filter(
      (app) => app.category.toLowerCase() === filter.toLowerCase()
    )
  }

  if (searchQuery) {
    filtered = filtered.filter((app) =>
      app.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }
  // Sort filtered apps based on isEnabled, then by name, and move coming soon apps to the end
  filtered.sort((a, b) => {
    const aIsComingSoon = upComingAppNames.includes(a.name)
    const bIsComingSoon = upComingAppNames.includes(b.name)

    // Move coming soon apps to the end
    if (aIsComingSoon && !bIsComingSoon) return 1 // a is coming soon, move it down
    if (!aIsComingSoon && bIsComingSoon) return -1 // b is coming soon, move it down

    // First sort by isEnabled (enabled apps first)
    if (a.isEnabled === b.isEnabled) {
      // If both have the same isEnabled status, sort alphabetically by name
      return a.name.localeCompare(b.name)
    }
    return a.isEnabled ? -1 : 1 // Move enabled apps to the top
  })

  return filtered
}

const AppTabsFilter = (props: any) => {
  const { appData, locationId, token, accountId, handleDeleteApp } = props
  const [upComingAppNames, setUpComingAppNames] = useState(
    appData.filter(
      (app: any) => app.description.toLowerCase() === 'coming soon'
    )
  )
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const tabs = ['All', 'Connected', 'POS', 'Channel', 'Other', 'Upcoming']

  useEffect(() => {
    const apps = appData.filter(
      (app: any) => app.description.toLowerCase() === 'coming soon'
    )
    setUpComingAppNames(apps.map((app: any) => app.name))
  }, [appData])
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredApps = useMemo(
    () => filterApps(appData, tabs[selectedTab], searchQuery, upComingAppNames),
    [appData, selectedTab, searchQuery, upComingAppNames]
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          borderBottom: '1px solid #eee',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          p: 0.2,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="App filters"
          sx={{ flexGrow: 1 }}
        >
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} sx={{ textTransform: 'capitalize' }} />
          ))}
        </Tabs>
        <Box sx={{ m: 0.5, width: '300px' }}>
          <CommonTextField
            id="outlined-basic"
            size="small"
            label={intl.formatMessage({ id: 'action_search' })}
            onChange={handleSearchChange}
            value={searchQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 1 }}>
        <Grid container spacing={1.5}>
          {filteredApps.length > 0 ? (
            filteredApps.map((app) => (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: 'flex', position: 'relative' }}
                key={app.id}
              >
                <ApplicationCard
                  app={app}
                  locationId={locationId}
                  disabled={upComingAppNames.includes(app.name) ? true : false}
                  token={token}
                  accountId={accountId}
                  isDelete={app.isEnabled}
                  handleDeleteApp={handleDeleteApp}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography>
                {intl.formatMessage({ id: 'discription_no_available_apps' })}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default AppTabsFilter
