import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import {
  Box,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Button,
  Divider,
} from '@mui/material'
import { MenuAdvancedOptions } from './MenuAdvancedOptions'
import { CollapsibleSection } from '../CollapsibleSection'
import { CommonPriceInputField } from '../CommonPriceField'
import { CommonTextField } from '../CommonTextField'
import { ServiceAvailabilityInput } from '../ServiceAvailabilityInput'
import { getCurrencySymbol } from '../../pages/util'
import { validationCheckUpdateforMenuItem } from '../../pages/Locations/CardSelect/Menu/utils'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useNavigate, useParams } from 'react-router-dom'
import { ShowOnlineView } from '../ShowOnlineSwitch'
import { RenderListView } from '../../pages/MenuV2/dialogs/ListView'
import { MenuItemPatchEntity } from '../../types'

const fulfillmentTypes: any = ['DELIVERY', 'PICKUP', 'DINE-IN']

export const ItemEditForm = (props: any) => {
  const {
    menuItemState,
    categoriesOptions,
    modifierGroupOptions,
    itemSpecificAvailability,
    setItemSpecificAvailability,
    handleSave,
    handleNavigation,
  } = props
  const { itemId } = useParams()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const navigate = useNavigate()
  const [valid, setValid] = useState(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [updatedState, setUpdatedState] = useState<MenuItemPatchEntity>({})

  const selectedModifierGroup = (
    updatedState?.modifierGroups ??
    menuItemState.modifierGroups ??
    []
  )?.map((modifierGroupId: any) => {
    const selectedModifiers = modifierGroupOptions?.find(
      (option: any) => option.id === modifierGroupId
    )
    return selectedModifiers
  })
  function validationCheckUpdate(object: any) {
    const validationResults = validationCheckUpdateforMenuItem(object)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate(newObject)
  }
  const handleAdvancedOptionsShow = () => {
    setShowAdvancedOptions(!showAdvancedOptions)
  }
  const handleShowOnlineChange = (event: any) => {
    updatedState.showOnline = event.target.checked
    validateAndSet(updatedState)
  }
  const handleNameChange = (event: any) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handleDescriptionChange = (event: any) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleFullFillmentType = (event: any, value: any) => {
    updatedState.fulfillmentTypes = value
    validateAndSet(updatedState)
  }
  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }
  const handleinStoreChange = (data: any) => {
    updatedState.inStorePrice = data
    validateAndSet(updatedState)
  }
  const handleCategoriesChange = (_: any, newValues: any) => {
    updatedState.categories = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const handleModifierGroupChange = (_: any, newValues: any) => {
    updatedState.modifierGroups = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const onAvailabilityChange = (availability: any) => {
    updatedState.serviceAvailability = availability
    validateAndSet(updatedState)
  }
  const handleItemAvailability = () => {
    setItemSpecificAvailability(!itemSpecificAvailability)
  }

  const handleModifierGroupNavigation = (id: string) => {
    const currentUrl = location.pathname // Get the current URL path
    const basePath = currentUrl.includes('/edit')
      ? currentUrl.split('/edit')[0] // Correctly split the URL before '/edit'
      : currentUrl
    let newUrl = `${basePath}/modifier-groups/${id}`
    // Use navigate to change the URL
    navigate(newUrl)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          {itemId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_item' })}
        </Typography>
        <Box>
          <Button onClick={() => handleNavigation()} sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleSave(updatedState)}
            disabled={!valid}
            color="success"
            variant="contained"
            sx={{ color: '#fff' }}
          >
            {itemId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {/* Form to get the name and description of the cateogry */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} mt={1}>
            <ShowOnlineView
              item={menuItemState}
              label={intl.formatMessage({ id: 'label_show_online' })}
              handleShowOnlineChange={(e: any) => handleShowOnlineChange(e)}
            />
          </Grid>
          {/* Name */}
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? menuItemState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={updatedState?.price ?? menuItemState.price!}
              onChange={handlePriceChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({
                id: 'label_inStorePrice',
              })}`}
              currency={currency}
              value={updatedState?.inStorePrice ?? menuItemState.inStorePrice!}
              onChange={handleinStoreChange}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={modifierGroupOptions ? modifierGroupOptions : []}
              value={
                modifierGroupOptions?.filter((option: any) =>
                  updatedState?.modifierGroups
                    ? updatedState.modifierGroups.includes(option.id)
                    : menuItemState.modifierGroups?.includes(option.id)
                ) || []
              }
              onChange={handleModifierGroupChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_modifier_group',
                  })}`}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={categoriesOptions ? categoriesOptions : []}
              value={
                categoriesOptions?.filter((option: any) =>
                  updatedState.categories
                    ? updatedState.categories.includes(option.id)
                    : menuItemState.categories?.includes(option.id)
                ) || []
              }
              onChange={handleCategoriesChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_categories',
                  })}`}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12} md={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_description',
              })}`}
              onChange={handleDescriptionChange}
              value={updatedState?.description ?? menuItemState?.description}
              rows={2}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleAdvancedOptionsShow()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
              >
                {intl.formatMessage({ id: 'label_advanced_options' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: showAdvancedOptions ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={menuItemState}
              show={showAdvancedOptions}
              children={
                <MenuAdvancedOptions
                  menuItemState={menuItemState}
                  updatedState={updatedState}
                  validateAndSet={validateAndSet}
                />
              }
            />
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleItemAvailability()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
              >
                {intl.formatMessage({ id: 'label_item_specific_availability' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: itemSpecificAvailability
                    ? 'rotate(180deg)'
                    : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={menuItemState}
              show={itemSpecificAvailability}
              children={
                <>
                  {/* fulfilment */}
                  <Grid item xs={12} md={5}>
                    <Autocomplete
                      multiple
                      limitTags={3}
                      id="checkboxes-tags-demos"
                      options={fulfillmentTypes ? fulfillmentTypes : []}
                      onChange={handleFullFillmentType}
                      value={
                        fulfillmentTypes?.filter((option: any) =>
                          updatedState?.fulfillmentTypes
                            ? updatedState.fulfillmentTypes.includes(option)
                            : menuItemState.fulfillmentTypes?.includes(option)
                        ) || []
                      }
                      disableCloseOnSelect
                      renderOption={(props, optionScope, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {optionScope}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={
                            updatedState?.fulfillmentTypes
                              ? updatedState.fulfillmentTypes?.join(', ')
                              : menuItemState?.fulfillmentTypes?.join(', ')
                          }
                          label={`${intl.formatMessage({
                            id: 'label_item',
                          })}${' '}${intl.formatMessage({
                            id: 'label_fulfilment_type',
                          })}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ pt: 0 }}>
                    <ServiceAvailabilityInput
                      availabilities={
                        updatedState?.serviceAvailability ??
                        menuItemState.serviceAvailability
                      }
                      onChange={onAvailabilityChange}
                      isMandatory={false}
                      fullWidth={true}
                    />
                  </Grid>
                </>
              }
            />
            <Divider sx={{ mt: 1 }} />
          </Grid>
        </Grid>
      </Box>
      {(updatedState?.modifierGroups?.length !== 0 ??
        menuItemState?.modifierGroups?.length !== 0) &&
      modifierGroupOptions?.length ? (
        <RenderListView
          listOptions={selectedModifierGroup}
          handleNavigation={handleModifierGroupNavigation}
          title={intl.formatMessage({
            id: 'label_modifier_groups',
          })}
        />
      ) : null}
    </>
  )
}
