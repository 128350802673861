import {
  Grid,
  InputAdornment,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import {
  useCatalogCategory,
  useCategory,
  useCategoryCreate,
  useItemCreate,
  useMenuModifierCreate,
  useMenuModifierGroupCreate,
  useMenuModifierGroups,
  useModifierGroups,
  useProducts,
} from '../../hooks'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Close, SearchOutlined } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import { MenuItemCreateEntity } from '../../types'
import { useIntl } from 'react-intl'
import { validateItemEntity } from '../../pages/Locations/CardSelect/Menu/utils'
import {
  CommonTextField,
  AppLoader,
  StyledButtonContainerForTable,
} from '../../components'
import { CardWithImage } from '../CardWithImage'

interface ProductListTableProps {
  locationId: string
  handleClose: (text: boolean) => void
}

export const ProductListTable: FC<ProductListTableProps> = (props) => {
  const { menuId } = useParams()
  const intl = useIntl()

  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >()
  const [searchedVal, setSearchedVal] = useState('')

  const products = useProducts(props.locationId!)

  const menuModifierGroup = useMenuModifierGroups(props.locationId!, menuId!)
  const menuCategory = useCategory(props.locationId!, menuId!)

  const itemsCreateMutation = useItemCreate(props.locationId!, menuId)
  const modifiersGroups = useModifierGroups(props.locationId!)
  const categories = useCatalogCategory(props.locationId!)

  const productList = products.data?.pages?.map((value) => value.data).flat()
  const modifiersGroupList = modifiersGroups.data?.pages
    ?.map((value) => value.data)
    .flat()
  const menuModifierGroupList = menuModifierGroup.data?.pages
    ?.map((value) => value.data)
    .flat()
  const menuCategoryList = menuCategory.data?.pages
    ?.map((value) => value.data)
    .flat()

  const categorysList = categories.data?.pages
    ?.map((value) => value.data)
    .flat()

  const modifierCreateMutation = useMenuModifierCreate(
    props.locationId!,
    menuId!
  )
  const modifierGroupCreateMutation = useMenuModifierGroupCreate(
    props.locationId!,
    menuId!
  )
  const categoryCreateMutation = useCategoryCreate(props.locationId!, menuId!)
  const [menuItemState, setMenuItemState] = useState<MenuItemCreateEntity[]>([])
  const isValid = useMemo(
    () =>
      menuItemState
        .map((menu) => validateItemEntity(menu))
        .every(Boolean, menuItemState),
    [menuItemState]
  )
  useEffect(() => {
    if (modifiersGroups.hasNextPage) {
      void modifiersGroups.fetchNextPage()
    }
    if (menuModifierGroup.hasNextPage) {
      void menuModifierGroup.fetchNextPage()
    }
  }, [modifiersGroups.data, menuModifierGroup.data])

  useEffect(() => {
    void menuModifierGroup.refetch()
    void menuCategory.refetch()
  }, [])
  useEffect(() => {}, [])
  const selectedProduct = (value: any, index: any, isSelected: boolean) => {
    setSelectedProductIndex(index)
    if (isSelected) {
      setMenuItemState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]

        // Add a new item to the array
        newState.push({
          catalogProductId: value.id,
          name: value.name,
          categories: value.categories || [],
          description: value.description,
          showOnline: value.showOnline ?? true,
          price: value.price,
          imageUrl: value?.imageUrl || '',
          modifierGroups: value.modifierGroups || [],
          position: value?.position,
          containsAlcohol: value?.containsAlcohol,
          containsTobacco: value?.containsTobacco,
          isBikeFriendly: value?.isBikeFriendly,
          nutritionalInfo: value?.nutritionalInfo,
        })
        return newState
      })
    } else {
      setMenuItemState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]

        // Find the index of the item to remove
        const index = newState.findIndex(
          (item) => item.catalogProductId === value.id
        )

        // Remove the item from the array
        newState.splice(index, 1)
        return newState
      })
    }
  }
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }
  if (itemsCreateMutation.isSuccess) {
    props.handleClose(false)
  }

  if (products.isLoading) {
    return <AppLoader />
  }

  const handleSubmit = async () => {
    const categoriesIds: string[] = []
    const modifierGroupIds: string[] = []

    // Prepare categories and modifier group ids
    menuItemState.forEach((menuItem) => {
      menuItem.categories?.forEach((categoryId) => {
        if (!categoriesIds.includes(categoryId)) {
          categoriesIds.push(categoryId)
        }
      })
      menuItem.modifierGroups?.forEach((groupId) => {
        if (!modifierGroupIds.includes(groupId)) {
          modifierGroupIds.push(groupId)
        }
      })
    })

    // Get category and modifier group details
    const [categories, modifierGroups] = await Promise.all([
      getCategoryList(categoriesIds),
      getModifierGroupList(modifierGroupIds),
    ])

    // Create items
    await Promise.all(
      menuItemState.map(async (menuItem: any) => {
        menuItem.categories = menuItem.categories?.length
          ? menuItem.categories.map((categoryId: any) => {
              const matchedCategory = categories.find(
                (category) => category.catalogCategoryId === categoryId
              )
              return matchedCategory ? matchedCategory.id : ''
            })
          : []
        menuItem.modifierGroups = menuItem.modifierGroups.length
          ? menuItem.modifierGroups.map((groupId: any) => {
              const matchedGroup = modifierGroups.find(
                (group) => group.catalogModifierGroupId === groupId
              )
              return matchedGroup ? matchedGroup.id : ''
            })
          : []
        await itemsCreateMutation.mutateAsync(menuItem)
      })
    )
    props.handleClose(false)
  }

  async function getCategoryList(categoryIds: string[]) {
    const categoryList: any[] = []

    const categoryDetails = categorysList?.filter((category: any) =>
      categoryIds.includes(category.id)
    )

    if (!categoryDetails) {
      return categoryList // Return an empty list if no category details are found
    }

    for (const category of categoryDetails) {
      // Check for a match in menuCategoryList
      const existingGroup = menuCategoryList?.find(
        (menuGroup: any) => menuGroup.catalogCategoryId === category.id
      )
      if (existingGroup) {
        // If a match is found, push the id into categoryList
        categoryList.push(existingGroup)
      } else {
        // If no match, create a new category
        const data = {
          name: category.name,
          imageUrl: category?.imageUrl || '',
          position: category?.position,
          showOnline: category.showOnline ?? true,
          catalogCategoryId: category.id,
          description: category.description || '',
        }
        try {
          const result = await categoryCreateMutation.mutateAsync(data)
          if (result.data) {
            categoryList.push(result.data)
          }
        } catch (error) {
          console.error('Error creating category:', error)
        }
      }
    }

    return categoryList
  }

  async function getModifierGroupList(modifierGroupIds: string[]) {
    const modifierGroupList: any[] = []

    const modifierGroupDetails = modifiersGroupList?.filter((modifier: any) =>
      modifierGroupIds.includes(modifier.id)
    )

    if (!modifierGroupDetails) {
      return modifierGroupList
    }
    for (const modifierGroup of modifierGroupDetails) {
      // Check for a match in menuModifierGroupList
      const existingGroup = menuModifierGroupList?.find(
        (menuGroup: any) =>
          menuGroup.catalogModifierGroupId === modifierGroup.id
      )

      if (existingGroup) {
        // If a match is found, push the id into modifierGroupList
        modifierGroupList.push(existingGroup)
      } else {
        // If no match, create modifiers and new modifier group
        const modifierIds = await createModifiers(modifierGroup.modifiers)
        const data = {
          name: modifierGroup.name,
          maxPermitted: modifierGroup.maxPermitted || 0,
          minPermitted: modifierGroup.minPermitted || 0,
          catalogModifierGroupId: modifierGroup.id,
          modifiers: modifierIds || [], // Ensure it's always an array
          description: '',
        }
        try {
          const result = await modifierGroupCreateMutation.mutateAsync(data)
          if (result.data && result.data.id) {
            modifierGroupList.push(result.data)
          }
        } catch (error) {
          console.error('Error creating modifier group:', error)
        }
      }
    }

    return modifierGroupList
  }

  async function createModifiers(modifiers: any) {
    const modifierList: string[] = []

    for (const modifierData of modifiers) {
      const data = {
        name: modifierData.name,
        catalogModifierId: modifierData.id,
        imageUrl: modifierData?.imageUrl || '',
        showOnline: modifierData.showOnline ?? true,
        description: '',
        price: modifierData.price,
        containsAlcohol: modifierData?.containsAlcohol,
        containsTobacco: modifierData?.containsTobacco,
        isBikeFriendly: modifierData?.isBikeFriendly,
        nutritionalInfo: modifierData?.nutritionalInfo,
        minPermitted: modifierData?.minPermitted,
        maxPermitted: modifierData?.maxPermitted,
      }

      try {
        const result = await modifierCreateMutation.mutateAsync(data)
        if (result.data && result.data.id) {
          modifierList.push(result.data.id)
        }
      } catch (error) {
        console.error('Error creating modifier:', error)
      }
    }

    return modifierList
  }

  return (
    <>
      <DialogTitle sx={{ p: 0 }} id="customized-dialog-title">
        {intl.formatMessage({ id: 'action_add_items' })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.handleClose(false)}
        disabled={
          categoryCreateMutation.isLoading ||
          itemsCreateMutation.isLoading ||
          modifierCreateMutation.isLoading ||
          modifierGroupCreateMutation.isLoading
        }
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      {categoryCreateMutation.isLoading ||
      itemsCreateMutation.isLoading ||
      modifierCreateMutation.isLoading ||
      modifierGroupCreateMutation.isLoading ? (
        <AppLoader />
      ) : (
        <>
          <DialogContent sx={{ p: 0 }} dividers>
            <Grid container sx={{ mt: 1 }} spacing={2}>
              <Grid item xs={12}>
                <CommonTextField
                  autoFocus
                  placeholder="Search for items..."
                  onChange={handleChangeText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {productList
                ?.filter(
                  (row) =>
                    !searchedVal.length ||
                    (row as any)?.name
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(searchedVal.toString().toLowerCase())
                )
                ?.map((product, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <CardWithImage
                      onClickCard={selectedProduct}
                      data={product}
                      isRedirect={false}
                      onDelete={() => {}}
                      isEditable={false}
                      isItem={true}
                      multiSelectItems={true}
                      index={index}
                      indexState={selectedProductIndex}
                    />
                  </Grid>
                ))}
            </Grid>
            {products.hasNextPage && (
              <StyledButtonContainerForTable
                display="flex"
                justifyContent="center"
                py={2}
              >
                <Button
                  onClick={() => products.fetchNextPage()}
                  disabled={products.isFetching}
                >
                  {products.isFetching
                    ? `${intl.formatMessage({ id: 'status_loading' })}`
                    : `${intl.formatMessage({ id: 'action_load_more' })}`}
                </Button>
              </StyledButtonContainerForTable>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              disabled={!isValid || !menuItemState.length}
              onClick={handleSubmit}
              color="success"
              variant="contained"
            >
              {intl.formatMessage({ id: 'action_create' })}
            </Button>
          </DialogActions>
        </>
      )}
    </>
  )
}
